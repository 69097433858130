import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const FeatureCard = ({ iconImage, title, children, ...rest }) => (
    <Box {...rest}>
      <Box className="d-flex align-items-center " mb={[0, 0, 0, 0]} pb={0}>
        <img src={iconImage} alt="" width="30px" className="mr-3" />
        <Text color="#000 " fontSize="14px" variant="medium">{children}</Text>
      </Box>
      <Box>
       
       
      </Box>
    </Box>
  );


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const HydraPackPage = () => (
  <>
  <Separator />
    {/* <!-- Content section 2 --> */}
    <Section py={4} bg="#f7f7fb">
      <Container className="pt-lg-5 pb-lg-5 pt-3 pb-3">

          <Row className="pb-4">
              <Col>
                <Iwrap>


<Itext as="h2">HydraFacial <span>Packages</span></Itext>
</Iwrap>
      <SecondText>
      HydraFacial Treatments at Dermamina

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="12" className="mb-lg-0">
        
          <table className="table_black">
 
 <tbody >
 <tr>
     <td data-column="Last Name"><Span> <br /> </Span></td>
     <td data-column="Job Title"><Span>Signature</Span></td>
     <td data-column="Last Name"><Span>Platinum</Span></td>
     <td data-column="Job Title"><Span>PlatinumPlus</Span></td>
     
   </tr>
  
   <tr>
   <td data-column="Last Name">Deep Cleanse</td>
<td data-column="Job Title">X</td>
<td data-column="Last Name">X</td>
<td data-column="Job Title">X</td>
   </tr>



   <tr>
   <td data-column="Last Name">Exfoliation</td>
   <td data-column="Job Title">X</td>
<td data-column="Last Name">X</td>
<td data-column="Job Title">X</td>
   </tr>
  
   <tr>
   <td data-column="Last Name">Chemical Peel</td>
   <td data-column="Job Title">X</td>
<td data-column="Last Name">X</td>
<td data-column="Job Title">X</td>
   </tr>

   <tr>
   <td data-column="Last Name">Extraction</td>
   <td data-column="Job Title">X</td>
<td data-column="Last Name">X</td>
<td data-column="Job Title">X</td>
   </tr>

   <tr>
   <td data-column="Last Name">Manual Extraction</td>
   <td data-column="Job Title"></td>
<td data-column="Last Name">X</td>
<td data-column="Job Title">X</td>
   </tr>

   <tr>
   <td data-column="Last Name">Lymphatic Drainage </td>
<td data-column="Job Title"></td>
<td data-column="Last Name">X</td>
<td data-column="Job Title">X</td>
   </tr>

   <tr>
   <td data-column="Last Name">Infusion of antioxidants</td>
   <td data-column="Job Title"></td>
<td data-column="Last Name">X</td>
<td data-column="Job Title">X</td>
   </tr>



   <tr>
   <td data-column="Last Name">Free Dermalux LED</td>
   <td data-column="Job Title"></td>
<td data-column="Last Name">X</td>
<td data-column="Job Title">X</td>
   </tr>

   <tr>
   <td data-column="Last Name">Bespoke Booster *</td>
   <td data-column="Job Title"></td>
<td data-column="Last Name"></td>
<td data-column="Job Title">X</td>
   </tr>

   <tr>
   <td data-column="Last Name">Price </td>
   <td data-column="Job Title">£120</td>
<td data-column="Last Name">£150</td>
<td data-column="Job Title">£210</td>
   </tr>

 </tbody>
 
 
 </table>



 <table className="table_black">
 
 <tbody >
 <tr>
     <td data-column="Last Name"><Span> <br /> </Span></td>
     <td data-column="Job Title"><Span>Signature</Span></td>
     <td data-column="Last Name"><Span>Platinum</Span></td>
     <td data-column="Job Title"><Span>PlatinumPlus</Span></td>
     
   </tr>

   <tr>
   <td data-column="Last Name">3 Sessions <br /> (10% off)</td>
<td data-column="Job Title">£324</td>
<td data-column="Last Name">£405</td>
<td data-column="Job Title">£567</td>
   </tr>
  
   <tr>
   <td data-column="Last Name">6 Sessions <br /> (5 + 1 Free)</td>
<td data-column="Job Title">£600</td>
<td data-column="Last Name">£750</td>
<td data-column="Job Title">£1050</td>
   </tr>

   <tr>
   <td data-column="Last Name">12 Sessions <br /> (9 + 3 Free)</td>
<td data-column="Job Title">£1080</td>
<td data-column="Last Name">£1350</td>
<td data-column="Job Title">£1890</td>
   </tr>

   

 </tbody>
 
 
 </table>



          </Col>
          
        </Row>
      </Container>
    </Section>
    <SeparatorEnd />
  </>
);

export default HydraPackPage;
